import { Box, Stack, Typography, styled } from '@mui/material';
import { CertifyOsWhiteLogo, RegisterTickIcon } from '../Icons';

const Text = styled(Typography)(({ theme }) => ({
  color: '#fff',
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing(8)} ${theme.spacing(8)} 0`,
}));
const Container = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
});
const IntroStack = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(8)}`,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
}));

export const IntroSection = () => {
  return (
    <Container>
      <LogoWrapper>
        <CertifyOsWhiteLogo />
      </LogoWrapper>
      <IntroStack spacing={2}>
        <Text variant="h3">
          The fastest way to license, credential, and monitor your provider
          network
        </Text>
        <Text variant="h5" className="mt-2xxl">
          <RegisterTickIcon className="mr-sm" /> CVO credentialing and
          monitoring
        </Text>
        <Text variant="h5">
          <RegisterTickIcon className="mr-sm" /> Provider licensing
        </Text>
        <Text variant="h5">
          <RegisterTickIcon className="mr-sm" /> Health plan enrollment
        </Text>
        <Text variant="h5">
          <RegisterTickIcon className="mr-sm" /> Provider data enrichment
        </Text>
      </IntroStack>
    </Container>
  );
};

import { Close } from '@mui/icons-material';
import {
  Typography,
  IconButton,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@mui/material';

export interface EditProviderDetailsModalProps {
  editProviderDetailsModalVisible: boolean;
  setEditProviderDetailsModalVisible: (value: boolean) => void;
  onSubmitEditProviderDetailsBtnClick: () => unknown;
  cardConfigFields: any;
  editProviderFormData: EditProviderDetailsModalFormData;
  updateEditProviderDetailsForm: (
    fieldName: string,
    value: string | boolean,
  ) => void;
  currentProviderDetails: EditProviderDetailsModalFormData;
  loading: boolean;
  isEditingProviderDetails: boolean;
}

export interface EditProviderDetailsModalFormData {
  providerId: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  npi: string;
  caqhProviderId: string;
  providerType: string;
  reason: string;
  acceptedTos: boolean;
}

const EditProviderDetailsModal: React.FC<EditProviderDetailsModalProps> = ({
  editProviderDetailsModalVisible,
  setEditProviderDetailsModalVisible,
  onSubmitEditProviderDetailsBtnClick,
  cardConfigFields,
  editProviderFormData,
  updateEditProviderDetailsForm,
  currentProviderDetails,
  loading,
  isEditingProviderDetails,
}) => {
  return (
    <Dialog
      open={editProviderDetailsModalVisible}
      onClose={() => setEditProviderDetailsModalVisible(false)}
    >
      <form>
        <DialogTitle sx={{ borderBottom: 'solid 1px rgba(0,0,0,.2)' }}>
          <Typography variant="h5">Edit Provider Details</Typography>
          <Typography variant="body1">
            Please edit provider details below
          </Typography>
          <IconButton
            onClick={() => setEditProviderDetailsModalVisible(false)}
            sx={{ position: 'absolute', top: 30, right: 20 }}
          >
            <Close fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack gap={2} component="form" marginTop={4} marginBottom={2}>
            <TextField
              fullWidth
              variant="filled"
              required={true}
              key={cardConfigFields?.firstName?.label}
              label={cardConfigFields?.firstName?.label}
              name={cardConfigFields?.firstName?.label}
              value={editProviderFormData.firstName}
              onChange={(e) =>
                updateEditProviderDetailsForm('firstName', e.target.value)
              }
              helperText={`Current Value: ${
                currentProviderDetails.firstName || ''
              }`}
            />{' '}
            <TextField
              fullWidth
              variant="filled"
              key={cardConfigFields?.middleName?.label}
              label={cardConfigFields?.middleName?.label}
              name={cardConfigFields?.middleName?.label}
              value={editProviderFormData.middleName}
              onChange={(e) =>
                updateEditProviderDetailsForm('middleName', e.target.value)
              }
              helperText={`Current Value: ${
                currentProviderDetails.middleName || ''
              }`}
            />
            <TextField
              fullWidth
              variant="filled"
              required={true}
              key={cardConfigFields?.lastName?.label}
              label={cardConfigFields?.lastName?.label}
              name={cardConfigFields?.lastName?.label}
              value={editProviderFormData.lastName}
              onChange={(e) =>
                updateEditProviderDetailsForm('lastName', e.target.value)
              }
              helperText={`Current Value: ${
                currentProviderDetails.lastName || ''
              }`}
            />
            <TextField
              fullWidth
              variant="filled"
              key={cardConfigFields?.npi?.label}
              label={cardConfigFields?.npi?.label}
              name={cardConfigFields?.npi?.label}
              value={editProviderFormData.npi}
              onChange={(e) =>
                updateEditProviderDetailsForm('npi', e.target.value)
              }
              helperText={`Current Value: ${currentProviderDetails.npi || ''}`}
            />
            <TextField
              fullWidth
              variant="filled"
              key={cardConfigFields?.caqhProviderId?.label}
              label={cardConfigFields?.caqhProviderId?.label}
              name={cardConfigFields?.caqhProviderId?.label}
              value={editProviderFormData.caqhProviderId}
              onChange={(e) =>
                updateEditProviderDetailsForm('caqhProviderId', e.target.value)
              }
              helperText={`Current Value: ${
                currentProviderDetails.caqhProviderId || ''
              }`}
            />
            <FormControl>
              <InputLabel id={'4'}>
                {cardConfigFields?.providerType?.label}
              </InputLabel>
              <Select
                fullWidth
                variant="filled"
                key={cardConfigFields?.providerType?.label}
                name={cardConfigFields?.providerType?.label}
                value={editProviderFormData.providerType}
                onChange={(e) =>
                  updateEditProviderDetailsForm('providerType', e.target.value)
                }
              >
                {cardConfigFields?.providerType?.options?.map(
                  (item: string, i: number) => {
                    return (
                      <MenuItem key={i.toString()} value={item as any}>
                        {item.toString()}
                      </MenuItem>
                    );
                  },
                )}
              </Select>
              <FormHelperText>
                {`Current Value: ${currentProviderDetails.providerType || ''}`}
              </FormHelperText>
            </FormControl>
            <Box
              sx={{
                mt: '1.5rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
              }}
            >
              <FormGroup>
                {
                  <>
                    <Typography>
                      You are about to change the provider details.
                    </Typography>
                    <Typography>
                      {"If you didn't mean this action, please click 'Cancel'."}
                    </Typography>
                    <Typography sx={{ mt: '1.5rem' }}>
                      Provide the change reason:
                    </Typography>
                    <TextField
                      value={editProviderFormData.reason}
                      label="Type Reason"
                      required={true}
                      variant="filled"
                      onChange={(e) =>
                        updateEditProviderDetailsForm('reason', e.target.value)
                      }
                    />
                  </>
                }
                <FormControlLabel
                  sx={{ mt: '1.5rem' }}
                  control={
                    <Checkbox
                      data-test="attest-checkbox"
                      checked={editProviderFormData.acceptedTos}
                      onChange={(e) =>
                        updateEditProviderDetailsForm(
                          'acceptedTos',
                          e.target.checked,
                        )
                      }
                    />
                  }
                  label="I attest that this action meets policy"
                />
              </FormGroup>
            </Box>{' '}
          </Stack>
        </DialogContent>

        <DialogActions
          sx={{
            justifyContent: 'space-between',
            borderTop: 'solid 1px rgba(0,0,0,.2)',
            padding: 2,
          }}
        >
          <Button
            onClick={() => setEditProviderDetailsModalVisible(false)}
            disabled={loading}
          >
            Cancel Change
          </Button>
          <Button
            onClick={onSubmitEditProviderDetailsBtnClick}
            color="error"
            data-test="submit-change-btn"
            disabled={!editProviderFormData.acceptedTos}
            variant="contained"
            startIcon={
              isEditingProviderDetails && (
                <CircularProgress size={14} color="inherit" />
              )
            }
          >
            Submit Change
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { EditProviderDetailsModal };

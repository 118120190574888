import React from 'react';
import { Button, ButtonProps, styled } from '@mui/material';

interface ToggleButtonProps {
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  sx?: ButtonProps['sx'];
  labelled?: boolean;
  disabled?: boolean;
  href?: string;
  download?: boolean;
  target?: string;
  startIcon?: React.ReactNode;
  component?: React.ReactNode;
}

const FilterButton = styled(Button, {
  shouldForwardProp: (props) => props !== 'labelled',
})<{ labelled?: boolean }>(({ theme, labelled }) => ({
  textTransform: 'none',
  marginLeft: theme.spacing(0.5),
  padding: labelled
    ? `${theme.spacing(0.5)} ${theme.spacing(1.5)}`
    : theme.spacing(0.5),
  minWidth: '0 !important',
  '&.MuiButton-textInherit': {
    backgroundColor: theme.palette.background.paper,
  },
  '& .cancel-icon': {
    marginRight: theme.spacing(0.5),
  },
}));

const ToggleButton: React.FC<ToggleButtonProps> = ({
  active,
  children,
  onClick,
  sx,
  labelled,
  href,
  download,
  startIcon,
  component,
  disabled,
  ...rest
}) => {
  const props: any = {
    size: 'medium',
    variant: 'text',
    color: 'inherit',
  };
  if (active) {
    props.variant = 'contained';
    props.color = 'primary';
  }
  return (
    <FilterButton
      {...props}
      {...rest}
      onClick={onClick}
      sx={sx}
      labelled={labelled}
      href={href}
      download={download}
      startIcon={startIcon}
      component={component}
      disabled={disabled}
    >
      {/* {active && <CancelIcon className="cancel-icon" fontSize="small" />} */}
      {children}
    </FilterButton>
  );
};

export default ToggleButton;
